<template>
    <div class="mx-4 my-4 md:mx-8">
      <h1 class="text-3xl text-gray-700 font-medium mb-2">Relatório Geral</h1>
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-4" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('campanhas') !== -1">
            <div class="rounded overflow-hidden shadow-lg">
                <div class="bg-blue-800 px-4 py-3">
                    <h4 class="text-base font-medium text-white">Envios Massivos</h4>
                </div>
                <div class="bg-blue-100 px-4 py-4">
                    <h3 class="text-2xl font-medium text-blue-700 mb-3">
                        {{ relatorio.massivo }}
                    </h3>
                    <p class="text-sm font-medium text-quartuary">Quantidade enviada massivamente do mês atual.</p>
                </div>
            </div>
        </div>
        <div class="col-span-12 md:col-span-4">
            <div class="rounded overflow-hidden shadow-lg">
                <div class="bg-green-700 px-4 py-3">
                    <h4 class="text-base font-medium text-white">Conversas {{ moment().format('DD/MM') }}</h4>
                </div>
                <div class="bg-green-100 px-4 py-4">
                    <h3 class="text-2xl font-medium text-green-700 mb-3">
                      {{ relatorio.conversas }}
                    </h3>
                    <p class="text-sm font-medium text-quartuary">Quantidade de conversas com interações hoje.</p>
                </div>
            </div>
        </div>
        <div class="col-span-12 md:col-span-4">
            <div class="rounded overflow-hidden shadow-lg">
                <div class="bg-indigo-600 px-4 py-3">
                    <h4 class="text-base font-medium text-white">Centros de Custo</h4>
                </div>
                <div class="bg-indigo-100 px-4 py-4">
                    <h3 class="text-2xl font-medium text-indigo-700 mb-3">
                      {{ relatorio.centrocustos }}
                    </h3>
                    <p class="text-sm font-medium text-quartuary">Registro total dos centro de custo cadastrados.</p>
                </div>
            </div>
        </div>
        <div class="col-span-12 md:col-span-6 md:mt-4">
            <div class="border shadow-xl rounded-md py-2 px-2">
              <apexchart v-if="data_grafico && data_grafico.length" type="bar" height="350" :options="chartOptions" :series="data_grafico"></apexchart>
            </div>
        </div>
        <div class="col-span-12 md:col-span-6 md:mt-4">
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-white">
                                    <tr>
                                        <th
                                            v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('campanhas') !== -1"
                                            scope="col"
                                            class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Envio Massivo 
                                        </th>
                                        <th
                                            scope="col"
                                            class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Envio Manual
                                        </th>
                                        <th
                                            scope="col"
                                            class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Percentual Retorno
                                        </th>
                                        <th
                                            scope="col"
                                            class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Data
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="el in list" :key="el._id">
                                        <td v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('campanhas') !== -1" class="text-sm text-blue-800 font-medium px-2 py-2">
                                            {{ el.totalEnviados }}
                                        </td>
                                        <td class="text-sm text-blue-800 font-medium px-2 py-2">
                                            {{ el.totalIniciadoManual }}
                                        </td>
                                        <td class="text-sm font-semibold 2xl:text-base px-2 py-2">
                                            {{ el.respostas }} <span class="text-green-700">({{ el.porcentagem }}%)</span>
                                        </td>
                                        <td class="text-sm font-medium px-2 py-2">
                                            {{ el.data }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    data() {
      return {
        apilz: window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API,
        moment: moment,
        data_grafico: [],
        list: [],
        relatorio: {
          massivo: 0,
          conversas: 0,
          centrocustos: 0
        },
        chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            colors: ['#00E396'],
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val + "%";
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            xaxis: {
              categories: [],
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
        }
      };
    },
    methods: {
      async start() {
        const loader = this.$loading.show({
            container: null,
            canCancel: true
        });
        const listReq = await this.$http.post(`/v1/chatV2/relatorio-home`);
        this.chartOptions.xaxis.categories = listReq.data.categorias;
        this.data_grafico = listReq.data.grafico;
        this.relatorio = listReq.data.relatorio;
        this.list = listReq.data.percentual_retorno.reverse();
        loader.hide();
      }
    },
    async beforeMount() {
      this.start();
    },
  };
  </script>
  